import webpImage from "../../assets/medical.webp";
import food from "../../assets/village/food.webp";

export const villagetext = {
  villagehead: "Embrace the Village Spirit",
  villageubtext1:
    "Unlike other running events, our participants don’t leave after crossing the finish line! And there is a reason for this. The party that happens at the Village!!",
  villageubtext2:
    "The Maruti Suzuki Arena Devils Circuit village is where participants, spectators, and partners all come together to have one awesome party!",
  parallaxdata: [
    {
      id: 1,
      head: "Concert",
      text: "The massive stage is the focal point of each event, where our entertainment partners kick things off with energetic warm-ups and lively dance sessions. Imagine the excitement and energy buzzing in the air as you groove to the beats, feeling the thrill of the moment. The hub of entertainment ensures that every participant is pumped up and ready to tackle the course.",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/concert.webp",
    },
    {
      id: 2,
      head: "Music",
      text: "A DJ gets you going as you walk into the village and makes you pull out moves you didn’t think you had. Groovy, smooth, and electrifying, the DJ hypes everyone up with their music and enhances the experience of a lifetime. ",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/jpeg-optimizer_Rectangle%2B314.webp",
    },
    {
      id: 3,
      head: "Food",
      text: "You will not go thirsty or hungry on the grounds, as we have fresh drinks and delicious food ready to be enjoyed by participants and their loved ones. From refreshing beverages to satisfying snacks, we've got everything you need to keep your energy up and your spirits high!",
      img: food,
    },
    {
      id: 4,
      head: "Engagement",
      text: "You won't have any opportunity to get bored on the grounds of Devils Circuit. This is because our partners create bespoke zones to not only entertain but also reward you. So, you have the chance to win exciting prizes, adding to the experience of a lifetime. ",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/770600-2-668d226e4ec40.webp",
    },
    {
      id: 5,
      head: "Medical",
      text: "We know you want to show off your battle bruises but the medical team is on standby for your scrapes and grazes.",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/jpeg-optimizer_medical.webp",
    },
    {
      id: 6,
      head: "Photos",
      text: "What's an event without capturing those unforgettable moments? At Maruti Suzuki Arena Devils Circuit, you'll find numerous photo ops throughout the village. Whether conquering obstacles, celebrating with your team, or soaking in the atmosphere, you’ll have plenty of chances to capture special moments. Strike a pose, flash a smile, and let the cameras roll!",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/photos.webp",
    },
  ],
};
