import React, { useState, useEffect } from "react";
import "../aboutUs/aboutus.css";
import { Link } from "react-router-dom";
import PageHead from "../common/pageHead/pageHead";
import FindRace from "../common/findRaceFooter/findrace";

const AboutUs = () => {
  const [showMore, setShowMore] = useState(false);
  const [textLength, setTextlength] = useState(900);
  const [screenWidth, setScreenWidth] = useState(window.screen.width);

  var aspectRatio = 1440 / 590;
  var bannerHeight = screenWidth / aspectRatio;

  const actualSize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    if (screenWidth < 1200 && screenWidth > 1024) {
      setTextlength(600);
    } else if (screenWidth < 1024 && screenWidth > 800) {
      setTextlength(400);
    } else if (screenWidth < 800 && screenWidth > 250) {
      setTextlength(10000);
    }
  });

  useEffect(() => {
    window.addEventListener("resize", actualSize);

    return () => {
      window.removeEventListener("resize", actualSize);
    };
  }, []);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const para1 =
    "India’s first and biggest Obstacle race the Maruti Suzuki Arena Devils Circuit, completed 10 years and 50 editions of its existence in 2022!";
  const para2 =
    "​Testament to the thrill-seeking spirit of Indians, this format of running has not only gained increasing popularity over the recent years but also a cult status amongst its enthusiasts.";

  const firstCardText =
    "The holy grail for DevilSlayers, this symbol is known to have appeared thousands of years ago on a rock. It had signaled to our forefathers, that the time for the tribe’s inception was near and thus we came to be! This symbol is what protects the village and the tribesmen when they are out beating their Devil on the track.";

  const secondCardtext ="While India is still catching up to the concept of obstacle racing, globally, it is an established phenomenon already. Here, the five-kilometrekilometer track is embedded with 15 crazy, fun, and challenging obstacles of varying difficulty levels. Once on it, you push yourself to draw strength not just from your core or muscles, but also from your gut. All puns intended.You reach an organic high by the time you’ve reached the fifth obstacle, and something within you—that you didn’t know existed—pushes you to conquer the Finish Line! You end the race feeling drunk with euphoria and bursting with adrenaline. The final obstacle always takes the cake, but you’ll have to be there to witness its impact. ";
  const thirdCardText =
    "Each Year Devilslayers gather together to beat their own Devil, that is, THEIR  FEAR OF FAILURE. The demolition of fear at the circuit is what helps them achieve things with fearlessness as they live their daily lives.";

  const fourthCardText =
    "In modern English, the word ‘Booyah’ is heralded as a unification of two words—BOO and YOU—which is used by humans when they accomplish a feat that puts them above a fellow human. It is rather distilled from the time it was incepted. The sacred chant, BOOYAH, which originated thousands of years ago, translates to ‘the organic high’ — the adrenaline pumped state you reach during the race when you have beaten your Devil! Though we don’t like the modern rendition of the word, we’ll keep the ‘sense of accomplishment’ part of it.";

  const fifthCardText ="‘Different’ is you experiencing an obstacle race unlike any other, and one that is arguably the biggest in Asia. Besides, we are 10 seasons/50 editions strong and boast a community of two-million Devilslayers nationwide. With our obstacles changing every year, Devils Circuit is the only platform where you can raise your own benchmark of healthy living.";
  const sixthCardText =
    "Complimenting the non linear nature of the track, the race starts and ends at The Village. The Village is where DevilSlayers gather before and after the race to indulge in merry-making with dance fitness sessions, community zones, food trucks, and music. Read more about The Village.";

  return (
    <div className="about_wrapper">
      <div className="about_main_div">
        <PageHead
          page_name={"About US"}
          page_head={"Who We Are"}
          text_1={para1}
          text_2={para2}
          media={
            "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/Be+a+devilslayers_11zon.webp"
          }
          media_type={"image"}
        />

        <div className="about_info_main_div">
          <div className="about_info_div">
            <p>
            An Obstacle Race does not only test your physical but also your mental strength. Face your fears—whether it's heights, water, or closed spaces—with the support of fellow devilslayers. Experience an adrenaline rush, euphoria, and an unparalleled sense of achievement!<br/>
A finisher medal at the finish line and the after party only adds to this memory that drives you forward to a healthier and more active lifestyle post race.<br/>
​It is no wonder that from 2028, Los Angeles Olympics onwards, Obstacle Racing is becoming an Olympic sport. Could a devilslayer represent our beloved India and potentially win a medal for all of us? Could that person be you? There is only one way to find out.
             
          {" "}
            </p>
          </div>
        </div>

        <div className="about_logo_card_div">
          <div className="about_logo_card">
            <div className="about_logo_card_left">
              <div className="about_logo_card_left_inner">
                <p className="about_card_left_heading">The Symbol</p>
                <div className="about_card_heading_border"></div>
                <p className="about_card_left_para">
                  {firstCardText.length > textLength ? (
                    <div>
                      <p>
                        {showMore
                          ? firstCardText.split("<br>").map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))
                          : firstCardText
                              .split("<br>")
                              .slice(0, textLength)
                              .map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))}
                        <button
                          className="about_card_show_more_btn"
                          onClick={toggleShowMore}
                        >
                          {showMore ? "See Less" : "See More"}
                        </button>
                      </p>
                    </div>
                  ) : (
                    <p>
                      {firstCardText.split("<br>").map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </p>
                  )}
                </p>
              </div>
            </div>
            <div className="about_card_right">
              <img
                src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/logo.gif"
                alt="img"
                className="about_card_logo_img"
              />
            </div>
          </div>
        </div>

        <div className="about_card_div2">
          <div className="about_card2">
            <div className="about_card_right2">
              <img
                src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/how+is+this+diff_11zon.webp"
                alt="img"
              />
            </div>

            <div className="about_card_left2">
              <div className="about_card_left_inner2">
                <p className="about_card_left_heading2">The Phenomenon</p>
                <div className="about_card_heading_border2"></div>
                <p className="about_card_left_para2">
                  {secondCardtext.length > textLength ? (
                    <div>
                      <p>
                        {showMore
                          ? secondCardtext.split("<br>").map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))
                          : secondCardtext
                              .split("<br>")
                              .slice(0, textLength)
                              .map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))}
                        <button
                          className="about_card_show_more_btn"
                          onClick={toggleShowMore}
                        >
                          {showMore ? "See Less" : "See More"}
                        </button>
                      </p>
                    </div>
                  ) : (
                    <p>
                      {secondCardtext.split("<br>").map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </p>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="about_card_div">
          <div className="about_card">
            <div className="about_card_left">
              <div className="about_card_left_inner">
                <p className="about_card_left_heading">Beating The Devil</p>
                <div className="about_card_heading_border"></div>
                <p className="about_card_left_para">
                  {thirdCardText.length > textLength ? (
                    <div>
                      <p>
                        {showMore
                          ? thirdCardText
                          : thirdCardText.slice(0, textLength) + "..."}
                        <button
                          className="about_card_show_more_btn"
                          onClick={toggleShowMore}
                        >
                          {showMore ? "See Less" : "See More"}
                        </button>
                      </p>
                    </div>
                  ) : (
                    <p>{thirdCardText}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="about_card_right">
              <img
                src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/beating+the+devils_11zon.webp"
                alt="img"
              />
            </div>
          </div>
        </div>

        <div className="about_card_div2">
          <div className="about_card2">
            <div className="about_card_right2">
              <img
                src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/phenomenon_11zon.webp"
                alt="img"
              />
            </div>

            <div className="about_card_left2">
              <div className="about_card_left_inner2">
                <p className="about_card_left_heading2">Booyah</p>
                <div className="about_card_heading_border2"></div>
                <p className="about_card_left_para2">
                  {fourthCardText.length > textLength ? (
                    <div>
                      <p>
                        {showMore
                          ? fourthCardText
                          : fourthCardText.slice(0, textLength) + "..."}
                        <button
                          className="about_card_show_more_btn"
                          onClick={toggleShowMore}
                        >
                          {showMore ? "See Less" : "See More"}
                        </button>
                      </p>
                    </div>
                  ) : (
                    <p>{fourthCardText}</p>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="about_card_div">
          <div className="about_card">
            <div className="about_card_left">
              <div className="about_card_left_inner">
                <p className="about_card_left_heading">
                  How this is Different?
                </p>
                <div className="about_card_heading_border"></div>
                <p className="about_card_left_para">
                  {fifthCardText.length > textLength ? (
                    <div>
                      <p>
                        {showMore
                          ? fifthCardText
                          : fifthCardText.slice(0, textLength) + "..."}
                        <button
                          className="about_card_show_more_btn"
                          onClick={toggleShowMore}
                        >
                          {showMore ? "See Less" : "See More"}
                        </button>
                      </p>
                    </div>
                  ) : (
                    <p>{fifthCardText}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="about_card_right">
              <img
                src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/booyah_11zon.webp"
                alt="img"
              />
            </div>
          </div>
        </div>

        <div className="about_card_div2">
          <div className="about_card2">
            <div className="about_card_right2">
              <img
                src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/Rectangle%2B321.webp"
                alt="img"
              />
            </div>

            <div className="about_card_left2">
              <div className="about_card_left_inner2">
                <p className="about_card_left_heading2">The Village</p>
                <div className="about_card_heading_border2"></div>
                <p className="about_card_left_para2">
                  {sixthCardText.length > textLength ? (
                    <div>
                      <p>
                        {showMore
                          ? sixthCardText
                          : sixthCardText.slice(0, textLength) + "..."}
                        <button
                          className="about_card_show_more_btn"
                          onClick={toggleShowMore}
                        >
                          {showMore ? "See Less" : "See More"}
                        </button>
                      </p>
                    </div>
                  ) : (
                    <p>
                      {sixthCardText}{" "}
                      <Link
                      to={"/village"}
                        style={{ textDecoration: "none" }}
                        className="about_card_link"
                      >
                        HERE
                      </Link>
                    </p>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FindRace heading="Unleashing the Power of Resistance!" para="Resilience empowers us to conquer obstacles with unwavering determination, embodying the strength of resistance."  btn_text="Find Your Race" />
    </div>
  );
};

export default AboutUs;
