export const photos = {
  photoshead: "Go Down The Memory Lane",
  photosubtext:
    "Hello devilslayers! So, you’ve written your DC story and are waiting to get your hands on those photographs now. Well, here they are! Find your city and use your selfie to download your images, pronto!",

  twothree: [
    {
      id: 1,
      city: "Indore",
      link: "https://www.snapd.me/search-photos/?eventId=02fc227b-c924-478f-8a33-412de8acabc8&id=20",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+1_11zon.webp",
    },
    {
      id: 2,
      city: "Mumbai",
      link: "",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+10_11zon.webp",
    },
    {
      id: 3,
      city: "Ahmedabad",
      link: "",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+2_11zon.webp",
    },
    {
      id: 4,
      city: "Pune",
      link: "",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+3_11zon.webp",
    },
    {
      id: 5,
      city: "Bengaluru",
      link: "",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+4_11zon.webp",
    },
    {
      id: 6,
      city: "Hyderabad",
      link: "",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+5_11zon.webp",
    },
    {
      id: 7,
      city: "Kochi",
      link: "",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+6_11zon+(1).webp",
    },
    {
      id: 8,
      city: "Chennai",
      link: "",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+7_11zon+(1).webp",
    },
    {
      id: 9,
      city: "Mohali",
      link: "",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+8_11zon+(1).webp",
    },
    {
      id: 10,
      city: "Delhi NCR",
      link: "",
      img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/city+9_11zon+(1).webp",
    },
  ],
};
